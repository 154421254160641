<template>
  <div class="career">
    <div class="container">
      <div class="career__inner">
        <div class="career__title">
          <h1>{{$t("career_page.title")}}</h1>
        </div>
        <div class="career__text">
          {{$t("career_page.text")}}
        </div>

        <div class="career__body">
          <div class="career__accordion accordion" v-if="this.cases.length !== 0">
            <div class="career__accordion-inner">
              <div class="accordion__title">Open positions:</div>
              <div class="accordion__item"
                   v-for="(item, i) in cases"
                   :key="item.id">
                <div
                    class="accordion__item-position accordion__header"
                    @click="() => toggle(i)"
                >
                  {{item.title}}

                  <div
                      class="accordion__item-icon"
                      :class="{'icon-open' : item.isOpen}"
                  >
                    <span></span>
                  </div>
                </div>
                <div
                    class="accordion__item-text accordion__body"
                    :ref="'body' + item.id"
                    :class="{active: item.isOpen}"
                >
                  <div class="accordion__item-inner">
                    <div class="accordion__item-desc">
                      {{item.info.infoText}}
                    </div>

                    <div class="accordion__item-req">
                      <h2>Requirements:</h2>

                      <ul>
                        <li
                            v-for="(requirements, index) in item.info.infoRequirements"
                            :key="index"
                        >
                          {{requirements}}
                        </li>
                      </ul>
                    </div>

                    <div class="accordion__item-ben">
                      <h2>Benefits and Perks:</h2>

                      <ul>
                        <li
                            v-for="(benefits, index) in item.info.infoBenefits"
                            :key="index"
                        >
                          {{benefits}}
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="no-position__text" v-else>
            <div class="no-position__title">
              {{$t("career_page.subtitle")}}
            </div>
            <div class="no-position__subtitle">

            </div>{{$t("career_page.subtext")}}
          </div>

          <div class="career__form form">
            <div class="form__inner">
              <div class="form__title form__item">
                Apply for a job
              </div>

              <div class="form__body form__item">
                <form action="" ref="form" @submit.prevent="sendEmail">
                  <div class="form__body-item">
                    <input
                        class="form__body-input form__input"
                        :class="{ 'form__body-error' : this.errorsInput[0].error}"
                        v-model="name"
                        type="text"
                        name="name"
                        :placeholder="$t('career_page.name_form')"
                    >
                    <span v-if="this.errorsInput[0].error">
                      {{this.errorsInput[0].error}}
                    </span>
                  </div>
                  <div class="form__body-item">
                    <input
                        class="form__body-input form__input"
                        :class="{ 'form__body-error': this.errorsInput[0].error}"
                        v-model="email"
                        type="email"
                        name="email"
                        :placeholder="$t('career_page.email_form')"
                    >
                    <span v-if="this.errorsInput[1].error">
                      {{this.errorsInput[1].error}}
                    </span>
                  </div>

                  <div class="form__body-item">
                    <input
                        class="form__body-input form__input"
                        :class="{ 'form__body-error': this.errorsInput[2].error}"
                        v-model="position"
                        type="text"
                        name="position"
                        :placeholder="$t('career_page.profession_form')"
                    >
                    <span v-if="this.errorsInput[2].error">
                      {{this.errorsInput[2].error}}
                    </span>

                    <div
                        class="form__body-cancel"
                        v-if="!this.errorsInput[2].isValid"
                        :class="{ 'visible': !this.errorsInput[2].isValid}"
                        @click="this.cancelMessage()"
                    >
                      <img src="../assets/valid_icon.svg" alt="">
                    </div>
                  </div>

                  <button class="btn__general btn__general-black form__body-btn" type="submit" value="submit">{{$t("career_page.button")}}
                    <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="#2E3139"/>
                    </svg>
                  </button>
                </form>
              </div>

              <div class="form__bg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: "Career",
  setup() {
    const siteData = reactive({
      title: "Careers | Join Webmil Web-production",
      description: "Become a part of Webmil. Check out the career opportunities we have right now. Contact us: hr@webmil.eu",
      keywords: "Careers",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },
  data(){
    return{
      name: '',
      email: '',
      position: '',
      errorsInput: [
        {error: ""},
        {error: ""},
        {error: "", isValid: true}
      ],
      cases: [
        // {
        //   id: 1,
        //   title: "Middle Project Manager",
        //   info: {
        //     infoText: "We are looking for a new member of the team of developers working with the popular Ukrainian directory. " +
        //         "As the PHP Developer, You'll be responsible for support and improvements of the current codebase written using the Symfony framework to meet " +
        //         "the continued business needs and refinement of existing functionality. " +
        //         "There are a lot of challenging and creative tasks ahead, so don't wait to join us!",
        //
        //     infoRequirements: [
        //         "2+ years of experience in PHP development",
        //         "Basic knowledge and experience with HTML, CSS, JavaScript",
        //         "Understanding of software engineering fundamentals (OOP, design patterns, TDD, SOLID, REST API design)",
        //         "Experience with Symfony 2/3/4",
        //         "Experience with MySQL and MSSQL",
        //         "Experience with Docker",
        //         "Experience with writing unit tests",
        //         "Experience with git",
        //         "Experience with Sphinx or Elasticsearch",
        //         "Experience with REST API"
        //     ],
        //     infoBenefits: [
        //       "Office at the centre of IF",
        //       "Participation in conferences and seminars",
        //       "Foreign languages courses",
        //       "Endless coffee and test",
        //       "Football and table tennis classes",
        //       "Free car and bicycle parking"
        //     ]
        //   }
        // },
      ],
    }
  },
  components: {

  },
  methods: {
    toggle(index){
      this.cases = this.cases.map((item, i) => {
        let $ref = this.$refs["body" + item.id];

        if(index === i){
          item.isOpen = !item.isOpen;

          if(item.isOpen){
            $ref.style.height =  $ref.scrollHeight + 'px';
          } else{
            $ref.style.height = 0;
          }
        } else {
          item.isOpen = false;
          $ref.style.height = 0;
        }

        return item;
      })
    },

    sendEmail(e){
      this.validateName();
      this.validateEmail();
      this.validateMessage();

      if(!this.errorsInput[0].error && !this.errorsInput[1].error && !this.errorsInput[2].error && this.errorsInput[2].isValid){
        emailjs.sendForm(
            'service_u4lvihc',
            'template_0mvjyub',
            e.target,
            'OGOmyZess3dbFvuxM',
        )
            .then((result) => {
              console.log('SUCCESS!', result.text);
            }, (error) => {
              console.log('FAILED...', error.text);
            });

        this.name = ''
        this.email = ''
        this.position = ''
      }
    },

    validateName(){
      if(!this.name){
        this.errorsInput[0].error = "Please type name";
      } else{
        this.errorsInput[0].error = "";
      }
    },

    validateEmail(){
      if(!this.email){
        this.errorsInput[1].error = "Please type email";
      } else{
        this.errorsInput[1].error = "";
      }
    },

    validateMessage(){
      if(!this.position){
        this.errorsInput[2].error = "Please type message";
        this.errorsInput[2].isValid = true;

        return;
      } else{
        this.errorsInput[2].error = "";
      }

      if(this.position && !this.checkMessage(this.position)){
        this.errorsInput[2].error = "Please add the position";
        this.errorsInput[2].isValid = false;
      } else{
        this.errorsInput[2].error = "";
        this.errorsInput[2].isValid = true;
      }
    },

    checkMessage(position){
      let regex = /^[A-Za-z]+$/;
      return regex.test(position);
    },

    cancelMessage(){
      this.position = "";
    }
  },
}
</script>

<style lang="scss">

@import "../base/styles/media.css";

.accordion__header{
  cursor: pointer;
}

.career{
  padding-top: 183px;

  &__title{
    h1{
      font-size: 72px;
      line-height: 72px;
      font-weight: bold;
      color: #2E3139;

      @media(max-width: 991px) {
        font-size: 60px;
        line-height: 66px;
      }

      @media(max-width: 575px){
        font-size: 48px;
        line-height: 60px;
      }
    }
  }

  @media(max-width: 991px){
    padding-top: 135px;
  }

  @media(max-width: 575px){
    padding-top: 96px;
  }

  &__text{
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #2E3139;
    margin-top: 30px;

    @media(min-width: 767px){
      max-width: 466px;
    }

    @media(max-width: 991px){
      margin-top: 20px;
    }

    @media(max-width: 575px){
      margin-top: 12px;
    }
  }

  &__body{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 84px;

    @media(max-width: 991px){
      flex-direction: column-reverse;
      align-items: stretch;
    }

    @media(max-width: 767px){
      margin-top: 45px;
    }

    @media(max-width: 575px){
      margin-top: 12px;
    }
  }

  .no-position__text{
    flex: 0 1 50%;
    padding-right: 100px;
  }

  .no-position__title{
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #2E3139;

    @media(max-width: 991px){
      font-size: 42px;
      line-height: 54px;
    }

    @media(max-width: 575px){
      font-size: 36px;
      line-height: 48px;
    }
  }

  .no-position__subtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2E3139;
    margin-top: 30px;

    @media(max-width: 991px){
      margin-top: 20px;
    }

    @media(max-width: 575px){
      margin-top: 10px;
    }
  }


  &__form{
    flex: 0 1 50%;
  }

  .accordion{
    flex: 0 1 50%;
    padding-right: 130px;

    @media(max-width: 1199px){
      padding-right: 80px;
    }

    @media(max-width: 991px){
      padding-right: 0px;
      margin-top: 20px;
    }
  }

  .accordion__title{
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #2E3139;
    margin-bottom: 14px;

    @media(max-width: 991px){
      font-size: 32px;
      line-height: 42px;
    }

    @media(max-width: 575px){
      font-size: 24px;
      line-height: 36px;
    }
  }

  .accordion__item{
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #2E3139;
    border-bottom: 1px solid #F2F2F2;

    h2{
      display: inline-block;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #2E3139;
      margin-top: 30px;
      margin-bottom: 12px;

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
      }
    }

    ul{
      margin-left: 17px;
    }

    &-position{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0px;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;

      @media(max-width: 991px){
        font-size: 20px;
        line-height: 30px;
      }

      @media(max-width: 575px){
        font-size: 14px;
        line-height: 24px;
        padding: 24px 0px;
      }
    }

    &-icon{
      width: 26px;
      height: 26px;
      position: relative;
      cursor: pointer;

      @media(max-width: 991px){
        width: 22px;
        height: 22px;
      }

      @media(max-width: 575px){
        width: 18px;
        height: 18px;
      }

      span{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 2px;
        height: 100%;
        background-color: #191A1B;
        transition: 0.2s all;
      }

      &:before{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #191A1B;
        margin-top: 11.5px;

        @media(max-width: 991px){
          margin-top: 9.5px;
        }

        @media(max-width: 575px){
          margin-top: 7.5px;
        }
      }
    }

    .icon-open{
      span{
        transform: rotate(90deg);
        top: -3px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
      }
    }

    &-text{
      transition: 0.2s height;
      height: 0px;
      overflow: hidden;
    }

    &-inner{
      padding-bottom: 30px;

      @media(max-width: 575px){
        padding-bottom: 24px;
      }
    }
  }



  .form{
    min-height: 450px;
    padding-bottom: 50px;
    position: relative;

    &:before{
      content: '';
      display: block;
      width: 242px;
      height: 242px;
      position: absolute;
      bottom: 0;
      z-index: -2;
      background-image: url("../assets/mail_circle-full.png");
      background-repeat: no-repeat;
      background-position: bottom left;

      @media(max-width: 991px){
        right: -35px;
        bottom: 20px;
        width: 190px;
        height: 120px;
        transform: rotate(90deg);
        background-image: url("../assets/mail_circle.png");
      }
    }

    @media(max-width: 991px){
      min-height: 420px;
    }

    @media(max-width: 575px){
      margin-left: -12px;
      margin-right: -12px;
      min-height: 400px;
    }

    &__item{
      position: relative;
    }

    div:not(:last-child){
      margin-bottom: 24px;
    }

    span{
      font-size: 12px;
      line-height: 18px;
      color: #D93737;
    }
  }



  .form__inner{
    padding: 36px 30px 32px 104px;
    position: relative;

    @media(max-width: 1199px){
      padding: 36px 20px 32px 60px;
    }

    @media(max-width: 991px){
      padding: 36px 30px 32px 30px;

    }

    @media(max-width: 575px){
      padding: 24px 12px 36px 12px;
    }

    .form__title{
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      color: #2E3139;
      margin-bottom: 24px;

      @media(max-width: 575px){
        font-size: 24px;
        line-height: 36px;
        text-align: center;
      }
    }

    form{
      display: flex;
      flex-direction: column;
    }

    .form__body{
      input{
        width: 100%;
      }
      &-item{
        position: relative;
      }

      &-error{
        border-bottom: 1px solid #D93737;
      }

      &-cancel{
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 5;
        cursor: pointer;
      }
    }

    .form__body-btn{
      align-self: flex-end;
      background: transparent;
      margin-top: 20px;

      @media(max-width: 575px){
        align-self: center;
      }

      @media (any-hover: hover) {
        &:hover  {
          color: #fff !important;
          background: rgba(46, 49, 57, 1);
          border: 1px solid rgba(46, 49, 57, 1);

          svg > path{
            fill: #fff;
          }
        }
      }

      &:active{
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;
        border: 2px solid rgba(46, 49, 57, 1);

        svg > path{
          fill: rgba(46, 49, 57, 1);
        }
      }
    }
  }

  .form__bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    @supports (backdrop-filter: blur(50px)) {
      & {
        backdrop-filter: blur(50px);
        -webkit-filter: blur(50px);
        -moz-filter: blur(50px);
        -o-filter: blur(50px);
        -ms-filter: blur(50px);
        filter: blur(50px);
      }
    }

    @supports not (backdrop-filter: blur(30px)){
      & {
        background-image: url("../assets/mail-form_bg-full.jpg");
        background-size: cover;

        @media(max-width: 991px){
          background-image: url("../assets/mail-form_bg.jpg");
        }
      }
    }
  }
}
</style>

